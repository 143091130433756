* {
  /* margin: 0; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-f7iyql {
  background-color: #1C2B3F !important;
  color: white !important;
}

.css-1wgcwo1-MuiButtonBase-root-MuiIconButton-root {
  color: white !important;
}

.css-nfrs8p-MuiButtonBase-root-MuiIconButton-root {
  color: white !important;
}

.recharts-cartesian-grid {
  display: none !important;
}

.recharts-cartesian-axis-tick-line {
  display: none !important;
}

.userTable tbody :nth-child(8) {
  display: none !important;
}

.userTable thead :nth-child(8) {
  display: none !important;
}

.anticon {
  color: white !important;
}

.ant-picker:hover {
  border: 2px solid #2ead9d !important;
}

@media(max-width: 576px) {
  .ant-picker-panels {
    flex-direction: column;
  }
}

.ant-picker-header {
  background-color: #1C2B3F !important;
}

.ant-picker-header>button {
  color: #ced0d2 !important;
}

.ant-picker-header-view button {
  color: white !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  background-color: #1C2B3F !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  /* background-color: #1C2B3F !important; */
  background-color: #1C2B3F !important;
}

.ant-picker-panel-container .ant-picker-panel {
  background-color: #2ead9d !important;
}

.ant-picker-cell-inner {
  color: white !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #ced0d2 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: #2ead9d !important;
}

/* ------------------------------------------------------------------------------------------------------------------ */
/* General Css start */
.centerIt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.goBack {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  left: 50px;
  cursor: pointer;

}

.goBack_para_text {
  margin: 0;
  color: #FFFFFF;
  font-size: 16px;
  margin-left: 20px;
}

.MainHeading {
  color: '#1E1E22';
  font-weight: bold;
  font-size: 35px;
  margin: 0;
}

.goBackFilled {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Pagination Css Start */
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  background-image: linear-gradient(#2ead9d, #115c54);
  border: none !important;
  border-radius: 5px !important;
}

.ant-pagination-item-active {
  border: none !important;
}

.ant-pagination-item-active a {
  background-image: linear-gradient(#2ead9d, #115c54);
}

.ant-pagination-item-active a {
  color: #FFFFFF !important;
}

.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link, .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: #2BA596 !important;
}
.ant-pagination-options .ant-select-selector{
  display: none !important;
}

/* Pagination Css End */
/* --Switch Css Start-- */

.ant-table-tbody .ant-table-cell div .ant-switch-checked {
  background-color: #d4d4d4 !important;
}

.ant-switch-handle::before {
  background-color: #176c63 !important;
}

.ant-table-tbody .ant-table-cell .ant-switch {
  background-color: #000 !important;
}

.detialsDiv_MainHead {
  font-size: 35px;
  font-weight: bold;
  color: #2BA596;
}

.detailsSubHead {
  font-size: 25px;
  color: #000000;
  font-weight: bold;
}

/* --Switch Css End-- */

/* General Css end */

/* Header Css Start */
.headerDiv {
  height: 15vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #2BA596;
}

.userNameDiv {
  color: #2BA596;
  margin: 0px 45px;
  height: 45px;
  width: 45px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userDetialsDiv {
  background: #ffffff;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  padding: 8px 8px 0px 8px;
}

.userDetialsDiv p {
  margin: 0px;
  color: #2BA596;
}

.userNamePara {
  font-weight: bold;
  margin: 0;
  font-size: 15px;
}

.ant-dropdown-menu {
  background: #2BA596 !important;
  /* padding: 11px 10px !important; */
  border-radius: 12px !important;
  width: 15rem;
}

/* Header Css End */

/* Footer Css Start */
.footer {
  margin-top: 16px;
  justify-content: center;
  background: #2BA596;
  align-items: center;
}

/* Footer Css End */

/* Dashboard Daterange arrow start */
.ant-picker-range-separator svg {
  color: #2BA596;
}

.dashboardChartsDiv {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.dashboardPickersHead {
  display: flex;
}

/* Dashboard Daterange arrow end */

/* User List Css Start */
.userFilterDiv {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.statusSearch .ant-select-selector {
  background-color: #1c2b3f !important;
  color: #FFFFFF;
  height: 43px !important;
  align-items: center !important;
  border: 2px solid #2ba596 !important;
  border-radius: 11px !important;
  width: 200px !important;
  margin-right: 10px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #1c2b3f !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #1c2b3f !important;
  color: #FFFFFF !important;
}

.ant-select-item {
  color: #FFFFFF;
}

.userListHeading {
  color: #1E1E22;
  font-size: 35px;
  font-weight: bold;
}

.usersSearchDiv .ant-select .usersSearchDiv .css-6qqtml-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px 10px;
}

.userListDiv::-webkit-scrollbar {
  display: none;
}

.userListDiv {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.ant-table-wrapper .ant-table .ant-table-content table thead tr th:first-child {
  border-top-left-radius: 11px;
  background-color: #2BA596;
}

.ant-table-wrapper .ant-table .ant-table-content table thead tr th {
  background-color: #2BA596;
  color: #FFFFFFD8;
  font-size: 20px;
}

.ant-table-wrapper .ant-table .ant-table-content table thead tr th:last-child {
  border-top-right-radius: 11px;
  background-color: #2BA596;
}

.usersSearchDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.tblBanned {
  margin: 0;
  border: 1px solid #F8604E;
  border-radius: 20px;
  background: #F8604E;
  color: #ffffff;
  text-align: center;
  padding: 8px;
}

.tblActive {
  margin: 0;
  border: 1px solid #4E5FF8;
  border-radius: 20px;
  background: #4E5FF8;
  color: #ffffff;
  text-align: center;
  padding: 8px;
}

.tblUnverified {
  margin: 0;
  border: 1px solid #cd1d08;
  border-radius: 20px;
  background: #cd1d08;
  color: #ffffff;
  text-align: center;
  padding: 8px;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table-cell:before {
  /* display: none; */
}

.css-6qqtml-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px 9px !important;
}

.ant-select-item-option-content {
  color: #FFFFFF;
}

.tblStatusText {
  margin: 0;
  font-size: 16px;
  color: #000000;
}

/* User List Css End */

/* User Detials Css Start */

.userDetails_Box {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.userDetails_Info {
  width: 100%;
  margin-left: 0px;
}

.userDetails_Info .ant-card-body {
  padding: 24px 24px 0px 0px;
}

.userCard {
  background-image: linear-gradient(#2ead9d, #115c54);
  align-items: center;
  padding: 10px;
  border-radius: 11px;
}

.usrDetail_Image {
  width: 160px;
  height: 170px;
  object-fit: cover;
  border-radius: 11px;
}

.userDetail_card {
  text-align: left;
  color: #FFFFFF;
}

.userDetail_card .userName_para {
  font-size: 20px;
  font-weight: bold;
}

.userDetail_card .userEmail_para {
  font-size: 14px;
}

.userProfilesDiv {
  width: 100%;
  background-image: linear-gradient(#2ead9d, #115c54) !important;
  border-radius: 11px !important;
}

.userProfilesDiv .ant-card-head {
  border: none;
}

.userProfilesDiv .ant-card-head-title {
  font-size: 30px;
  font-weight: bold;
  color: #FFFFFF;
}

.userProfilesDiv .ant-card-body span {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 25px;
  cursor: pointer;
  color: #ffffff;
  font-weight: bold;
}

.userProfilesDiv .ant-card-meta-detail {
  text-align: left;
}

.userProfilesDiv .ant-card {
  border: none;
}

.userProfilesDiv .ant-card .ant-card-body {
  background-image: linear-gradient(#8c8c8c, #4e4e4e) !important;
}

.userProfilesDiv .ant-card-meta-title, .ant-card-meta-description {
  color: #FFFFFF !important;
}

.userDetails_Switch {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(#2ead9d, #115c54);
  padding: 0px 10px;
  height: 100px;
  border-radius: 10px;
  margin-top: 10px;
}

.userDetails_Switch p {
  margin: 0;
  color: #d4d4d4;
}

.userDetails_Switch .ant-switch-checked {
  background-color: #d4d4d4 !important;
}

.userDetails_Switch .ant-switch {
  background-color: #000;
}

.status_filter .ant-select-arrow {
  right: 17px
}

.repProfile_Div {
  display: flex;
}



/* User Detials Css End */

/* News List Css Start */
.news_card {
  cursor: pointer;
  padding: 15px 0px;
  background: rgb(255, 255, 255);
  align-items: center;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px 7px !important;
}

.btnIcons {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 5px;
  color: #2ba596;
}

.btnAddNews {
  background: #2ba596;
  color: #ffffff;
  display: flex;
  align-items: center;
  border: 1px solid #2ba596;
  border-radius: 6px;
  padding: 10px 10px;
  cursor: pointer;
}

.btnAddNews .btnIcons {
  color: #FFFFFF;
}

.btnModal {
  width: 100%;
  border-radius: 7px;
  padding: 5px;
  color: #2ca898;
  border: none;
  cursor: pointer;
}

/* News List Css End */

/* Add News Css start */
.addNews {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 7px;
  padding: 15px 10px;
  border-radius: 6px;
}

.txtDescription textarea.ant-input {
  border: 1px solid #2BA596;
  border-radius: 6px;

}

.btnDashed {
  display: flex;
  padding: 10px 10px;
  border: 1px dashed #2BA596;
  border-radius: 5px;
  background: #ffffff;
  cursor: pointer;
}

.btnSolid {
  padding: 10px 71px;
  border: 1px solid #2BA596;
  border-radius: 10px;
  background: #2BA596;
  color: #FFFFFF;
  cursor: pointer;
}

.uploadImgSection {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.onHoverImg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px
}

.onHoverImg h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-bottom: 0;
}

.onHoverImg:hover {
  display: none;
}

/* Add News Css End */

/* News Details Css Start */
.detailsDiv {
  background: #FFFFFF;
  padding: 20px 40px;
  position: relative;
}

.detailsDiv_img img {
  width: 100%;
}

.detailsDiv_headings {
  margin-left: 15px;
  text-align: left;
}

.detailsDiv_icon {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

.newDetails_imglist {
  border-radius: 11px;
  /* margin: 15px; */
}

.detailsDiv_icon img {
  margin: 5px;
}

.detailsDiv_Scroll {
  max-height: 22rem;
  overflow: scroll;
}

.detailsDiv_Scroll::-webkit-scrollbar {
  display: none;
}

.detailsDiv_Scroll p {
  text-align: left;
  font-size: 20px;
  color: #000000;
}

.detailsDiv_Scroll_img {
  width: 100%;
}

.btnNewsClose {
  background: #2BA596;
  border: none;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 70px;
  border-radius: 11px;
  cursor: pointer;
}

/* News Details Css End */

/* Edit News Css Start */
.editNews_imgSec {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.uploaded_img {
  border: 1px dashed #2ba596;
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px;

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
}

.uploadImage {
  width: 100%;
  border-radius: 11px;
}

.uploaded_img p {
  margin: 0;
}

.cancel_icon {
  cursor: pointer;
  color: red;
}

/* Edit News Css End */

/* Message Css State */
.msgCardUsrName {
  color: #1E1E22;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-left: 5px;
}

.msgEllipsisDiv {
  text-align: justify;
  width: 100%;
}

.msgEllipsisDiv p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 10px;
}

.msgActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.msgActions button {
  width: 100%;
}

.msgBtn {
  border-radius: 5px !important;
  border: 1px solid #2BA596 !important;
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
}

.msgBtnDel {
  color: red !important;
}

.msgBtnSolid {
  background-color: #2BA596 !important;
  color: #FFFFFF !important;
}

.msgModal .ant-modal-content {
  border-radius: 11px;
}

.replyModal .ant-modal-content {
  border-radius: 13px;
}

.replyModal .ant-modal-body {
  background-image: linear-gradient(#2ead9d, #115c54);
  border-radius: 11px;

}

.replyModalHead {
  text-align: center;
}

.replyModalHead h2 {
  color: #FFFFFF;
  font-weight: bold;
}

.replybtnSubmit {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.replybtnSubmit button {
  border-radius: 9px !important;
  background-color: #FFFFFF !important;
  color: #2ba596 !important;
  font-size: 18px;
}

.delConfirmModal {
  border-radius: 11px;

}

.delConfirmModal .ant-modal-content {
  background-image: linear-gradient(#2ead9d, #115c54);
  border: none;
  border-radius: 11px;
}

/* Message Css End */

/* Reported Css Start */
/* .ant-tabs>.ant-tabs-nav, .ant-tabs>div>.ant-tabs-nav {
  display: none;
} */

.reportedTabs .ant-tabs-tab {
  background: #f1f1f1;
  padding: 19px 70px;
  border-radius: 11px;
  color: #FFFFFF;
  font-weight: bold;
  color: #2ba596;
}

.reportedTabs {
  width: 100%;
}

.reported_profile_img {
  /* width: 140px; */
  width: 140px;
  height: 140px;
}

.reportedTabs .ant-tabs-tab:hover {
  color: #2ba596;
}

.reportedTabs .ant-tabs-nav-list .ant-tabs-tab-active {
  background-color: #2ba596 !important;
}

.reportedTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f1f1f1 !important;
}

.reportedTabs .ant-tabs-ink-bar {
  display: none !important;
}

.reported_filter {
  position: absolute;
  right: 0;
  top: 8rem;
  z-index: 1;
}

.reported_Cards {
  justify-content: space-between;
}

/* .reported_Profile_Details {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.reported_textDiv {
  text-align: left;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reported_Cards .lables {
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
}

.reported_Cards .lables p {
  margin: 0px;
}

.reported_Cards .reported_profileName {
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
}

.reported_Cards .reported_para {
  font-size: 14px;
  color: #FFFFFF;
}

.reported_para p {
  margin: 0px;
}

.reported_CardActions {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 15px;
}



.btn_report {
  color: #2BA596;
  font-size: 18px;
  font-weight: bold;
  padding: 5px 14px;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.reported_Reason_div {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin-top: 10px;
}

.reason_Para {
  margin-left: 5px;
  text-align: justify;
  color: #FFFFFF;
  margin-bottom: 0px;
}

.details_box {
  display: flex;
  align-items: center;
}

.details_box p {
  margin: 5px 0px;
  color: #FFFFFF;
  font-size: 14px;
}

.modal_userName {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.details_box .val {
  margin-left: 5px;
}

.RCC_headerDetails {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  /* align-items: center; */
}

.repo_header_img img {
  width: 100%;
}

/* ------Reported comments------ */
.rep_comments_card {
  text-align: left;
  border-radius: 20px;

}

.rep_comments_card .ant-card-body {
  background-image: linear-gradient(#2ead9d, #115c54);
  border-radius: 20px;
}

.RC_Details_Head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_mainHeading {
  font-size: 20px;
  color: #FFFFFF;
  font-weight: bold;
}

.C_ON_Pro_Div {
  margin-top: 2rem;
}

.RC_header_details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #FFFFFF;
  font-size: 12px;
}

.comments_Details {
  display: flex !important;
  flex-direction: row;
}

.comments_Details p {
  color: #FFFFFF;
}

.site_Name, .category_Name, .reportedBy_mail {
  margin-left: 5px;
}

.RC_header_details .span-1 {
  margin-right: 5px;
}

.RC_header_details .span-3 {
  margin-left: 5px;
}

.RC_header_para p {
  margin: 0;
  text-align: justify;
  font-size: 14px;
  color: #FFFFFF;

}

.pro_Name {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
}

.pro_Date {
  margin-bottom: 0;
}

.site_Details {
  display: flex;
}

.cate_Details {
  display: flex;
}

.site_Details p {
  margin-bottom: 0;
}

.cate_Details p {
  margin-bottom: 0;
}

.repo_com_reportedBy {
  display: flex !important;
  flex-direction: row;
  margin-top: 2rem;
  align-items: baseline;
}

.reported_By, .reportedBy_mail {
  margin-bottom: 0;
}

.reportedBy_mail {
  font-size: 16px;
  color: #FFFFFF;
}

/* Reported Css End */

@media screen and (max-width: 1024px) {
  .headerDiv {
    height: 5em;
  }

  .reported_filter {
    top: 5rem;
  }

  .reportedTabs {
    margin-top: 50px !important;
  }

  .reported_CardActions {
    flex-direction: column;
  }

  .reported_CardActions .btn_report {
    margin-top: 5px;
  }
}

@media screen and (max-width: 770px) {
  .detailsDiv_img img {
    width: 80%;
  }

  .detailsDiv_headings {
    margin-top: 20px;
    margin-left: 0px;
  }

  .detailsDiv {
    padding: 0;
  }

  .detailsDiv_icon {
    position: relative !important;
    right: 0;
    top: 0;
  }

  .reported_Reason_div {
    flex-direction: column;
  }

  .RC_header_details {
    margin-top: 15px;
  }

  .RC_header_para p {
    margin-top: 15px;
  }

  .RCC_headerDetails {
    flex-direction: column;
  }

  .comments_Details {
    margin-top: 15px;
    flex-direction: column;
  }

  .C_ON_Pro_Div {
    margin-top: 25px;
  }

  .repo_comm_profile {
    margin-top: 1rem;
  }

  .repo_com_reportedBy {
    flex-direction: column;
  }

  .reportedBy_mail {
    margin: 0;
  }
}

@media screen and (max-width: 450px) {
  .uploadImage {
    width: 15rem;
  }

  .btnDashed {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .btnSolid {
    width: 100%;
  }

  /* Dashboard Css start */
  .dashboardChartsDiv {
    flex-direction: column;
  }

  /* Dashboard Css end */

  /* User List Css Start*/
  .usersSearchDiv {
    flex-direction: column;
  }

  .userFilterDiv {
    width: 100%;
    flex-direction: column;
  }

  .status_filter {
    width: 100%;
  }

  .statusSearch {
    width: 100%;
  }

  .statusSearch .ant-select-selector {
    width: 100% !important;
  }

  .textSearch {
    width: 100% !important;
    margin-top: 10px;
  }

  .textSearch div {
    width: 100% !important;
  }

  .tblActive {
    border-radius: 10px;
  }

  .tblBanned {
    border-radius: 10px;
  }

  .tblUnverified {
    border-radius: 10px;
  }


  /* User List Css End */
  /* User Details Start */
  .userDetails_Info .ant-card-body {
    padding: 0px !important;
  }

  /* User Details End */

  /* Edit News Start*/
  .editNews_imgSec {
    flex-direction: column;
  }

  /* Edit News End*/
}